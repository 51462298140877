import { FIREBASE_CONFIG } from '../constants/constants'
import { initializeApp } from 'firebase/app'
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  updatePassword
} from 'firebase/auth'

import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore'
import { LoginResultDataType, LogoutResultDataType } from '../dataStructure'

const firebaseApp = initializeApp(FIREBASE_CONFIG)
const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)

export const doSignInWithEmailAndPassword = async (email: string, password: string): Promise<LoginResultDataType> => {
  const firebaseUser = await signInWithEmailAndPassword(auth, email, password)
  try {
    const document = await getDoc(doc(firestore, 'users', firebaseUser.user.uid))
    if (document.exists() && document.data()) {
      if (document.data().approvedByAdmin && document.data().phone !== '' && document.data().name !== '') {
        return { success: true, uid: firebaseUser.user.uid }
      } else if (!document.data().approvedByAdmin) {
        // If user is not approved yet, redirect to onboarding page
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Account is not approved yet',
          code: 510
        }
      } else if (document.data().phone === '' || document.data().name === '') {
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Incomplete user profile',
          code: 520
        }
      } else {
        // If it reaches this unknown catchall state, immediately sign the user out
        await doSignOut()
        return {
          success: false,
          details: 'Login attempt failed.',
          code: 530
        }
      }
    } else {
      // If user document does not exist, immediately sign the user out
      await doSignOut()
      return {
        success: false,
        details: 'Login attempt failed.',
        code: 540
      }
    }
  } catch (error) {
    return { success: false, details: `${error}`, code: 599 }
  }
}

export const doSignInWithGoogle = async (): Promise<LoginResultDataType> => {
  const googleProvider = new GoogleAuthProvider()
  const firebaseUser = await signInWithPopup(auth, googleProvider)
  try {
    const document = await getDoc(doc(firestore, 'users', firebaseUser.user.uid))
    if (document.exists() && document.data()) {
      if (document.data().approvedByAdmin && document.data().phone !== '' && document.data().name !== '') {
        return { success: true, uid: firebaseUser.user.uid }
      } else if (!document.data().approvedByAdmin) {
        // If user is not approved yet, redirect to onboarding
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Account is not approved yet',
          code: 510
        }
      } else if (document.data().phone === '' || document.data().name === '') {
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Incomplete user profile',
          code: 520
        }
      } else {
        // If it reaches this unknown catchall state, immediately sign the user out
        await doSignOut()
        return {
          success: false,
          details: 'Login attempt failed.',
          code: 530
        }
      }
    } else {
      // If user document does not exist, create a default user doc
      await setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
        provider: '',
        name: '',
        email: '',
        phone: '',
        enabled: true,
        isTenant: false,
        approvedByAdmin: false,
        updatedAt: new Date(),
        updatedBy: firebaseUser.user.uid,
        createdAt: new Date(),
        createdBy: firebaseUser.user.uid
      })
      return { success: false, details: 'New user', code: 540 }
    }
  } catch (error) {
    return { success: false, details: `${error}`, code: 550 }
  }
}

export const doSignInWithTwitter = async (): Promise<LoginResultDataType> => {
  const twitterProvider = new TwitterAuthProvider()
  const firebaseUser = await signInWithPopup(auth, twitterProvider)
  try {
    const document = await getDoc(doc(firestore, 'users', firebaseUser.user.uid))
    if (document.exists() && document.data()) {
      if (document.data().approvedByAdmin && document.data().phone !== '' && document.data().name !== '') {
        return { success: true, uid: firebaseUser.user.uid }
      } else if (!document.data().approvedByAdmin) {
        // If user is not approved yet, redirect to onboarding
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Account is not approved yet',
          code: 510
        }
      } else if (document.data().phone === '' || document.data().name === '') {
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Incomplete user profile',
          code: 520
        }
      } else {
        // If it reaches this unknown catchall state, immediately sign the user out
        await doSignOut()
        return {
          success: false,
          details: 'Login attempt failed.',
          code: 530
        }
      }
    } else {
      // If user document does not exist, create a default user doc
      await setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
        provider: '',
        name: '',
        email: '',
        phone: '',
        enabled: true,
        isTenant: false,
        approvedByAdmin: false,
        updatedAt: new Date(),
        updatedBy: firebaseUser.user.uid,
        createdAt: new Date(),
        createdBy: firebaseUser.user.uid
      })
      return { success: false, details: 'New user', code: 540 }
    }
  } catch (error) {
    return { success: false, details: `${error}`, code: 550 }
  }
}

export const doSignInWithFacebook = async (): Promise<LoginResultDataType> => {
  const facebookProvider = new FacebookAuthProvider()
  try {
    const firebaseUser = await signInWithPopup(auth, facebookProvider)
    const document = await getDoc(doc(firestore, 'users', firebaseUser.user.uid))
    if (document.exists() && document.data()) {
      if (document.data().approvedByAdmin && document.data().phone !== '' && document.data().name !== '') {
        return { success: true, uid: firebaseUser.user.uid }
      } else if (!document.data().approvedByAdmin) {
        // If user is not approved yet, redirect to onboarding
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Account is not approved yet',
          code: 510
        }
      } else if (document.data().phone === '' || document.data().name === '') {
        return {
          success: false,
          uid: firebaseUser.user.uid,
          details: 'Incomplete user profile',
          code: 520
        }
      } else {
        // If it reaches this unknown catchall state, immediately sign the user out
        await doSignOut()
        return {
          success: false,
          details: 'Login attempt failed.',
          code: 530
        }
      }
    } else {
      // If user document does not exist, create a default user doc
      await setDoc(doc(firestore, 'users', firebaseUser.user.uid), {
        provider: '',
        name: '',
        email: '',
        phone: '',
        enabled: true,
        isTenant: false,
        approvedByAdmin: false,
        updatedAt: new Date(),
        updatedBy: firebaseUser.user.uid,
        createdAt: new Date(),
        createdBy: firebaseUser.user.uid
      })
      return { success: false, details: 'New user', code: 540 }
    }
  } catch (error) {
    return { success: false, details: `${error}`, code: 550 }
  }
}

export const doSignOut = async (): Promise<LogoutResultDataType> => {
  try {
    await signOut(auth)
    return { success: true }
  } catch (error) {
    return { success: false, details: `${error}` }
  }
}

export const doPasswordReset = async (email: string) => {
  await sendPasswordResetEmail(auth, email)
}

export const doPasswordUpdate = async (password: string) => {
  if (auth.currentUser) {
    await updatePassword(auth.currentUser, password)
  }
}

export default firebaseApp
