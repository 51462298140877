import React, { useContext, useState, useEffect } from 'react'

import { Navigate, useNavigate, useLocation } from 'react-router-dom'

import 'tailwindcss/tailwind.css'
import { AuthContext } from '../../context/firebaseContext'
import { FirestoreContext } from '../../context/firestoreContext'
import { doSignInWithFacebook, doSignInWithGoogle, doSignInWithTwitter } from '../../firebase/firebaseConfig'
import { LoginResultDataType } from '../../dataStructure'
import { ROUTE_PROTECTED_HOME, ROUTE_USER_ONBOARDING, ROUTE_USER_PROFILE } from '../../constants/routes'
import Logo from '../../img/odeo-logo.png'

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  // get the user state from the context
  const { user } = useContext(AuthContext)
  const { userDoc } = useContext(FirestoreContext)
  const [loadingLoginRequest, setLoadingLoginRequest] = useState(false)
  const [loginProvider, setLoginProvider] = useState('')

  useEffect(() => {
    async function fetchData() {
      if (loadingLoginRequest) {
        let res: LoginResultDataType
        switch (loginProvider) {
          case 'google':
            res = await doSignInWithGoogle()
            break
          case 'twitter':
            res = await doSignInWithTwitter()
            break
          case 'facebook':
            res = await doSignInWithFacebook()
            break
          default:
            res = { success: false }
        }

        if (res.success) {
          const locationState = location.state as any
          if (locationState && locationState.referrer && locationState.referrer.pathname) {
            navigate(locationState.referrer.pathname)
            setLoadingLoginRequest(false)
          } else {
            navigate(ROUTE_USER_PROFILE)
          }
        } else if (res.code === 510 || res.code === 520 || res.code === 540) {
          // Incomplete user profile information. Redirect to page for user to fill in required detail
          navigate(ROUTE_USER_ONBOARDING, { state: { uid: res.uid } })
          setLoadingLoginRequest(false)
        }
      }
    }
    fetchData()
  }, [loadingLoginRequest, location.state])

  const handleSignInWithGoogle = () => {
    // set loadingLoginRequest to true to trigger useEffect function
    setLoginProvider('google')
    setLoadingLoginRequest(true)
  }

  const handleSignInWithTwitter = () => {
    // set loadingLoginRequest to true to trigger useEffect function
    setLoginProvider('twitter')
    setLoadingLoginRequest(true)
  }

  const handleSignInWithFacebook = () => {
    // set loadingLoginRequest to true to trigger useEffect function
    setLoginProvider('facebook')
    setLoadingLoginRequest(true)
  }

  return (
    <>
      {user && userDoc && userDoc.docData && !loadingLoginRequest ? (
        <Navigate
          to={
            location.state && (location.state as any).referrer && (location.state as any).referrer.pathname
              ? (location.state as any).referrer.pathname
              : userDoc.docData.approvedByAdmin
              ? ROUTE_PROTECTED_HOME
              : ROUTE_USER_ONBOARDING
          }
        />
      ) : (
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col mt-44 py-12 px-6 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <div className="grid grid-cols-2 mb-6">
                  <img className="h-14 -ml-3 w-auto col-span-1" src={Logo} alt="Odeo" />
                  <h2 className="mt-1 text-4xl font-extrabold text-gray-800 col-span-1">Connectors</h2>
                </div>
                <h2 className="mt-4 text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
              </div>

              <div className="mt-8">
                <div>
                  <div>
                    <p className="text-sm font-medium text-gray-700">{/* with */}</p>

                    <div className="mt-10 grid grid-cols-3 gap-3">
                      <div>
                        <a
                          href="#"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          onClick={() => handleSignInWithGoogle()}
                        >
                          <span className="sr-only">Sign in with Google</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                              <path d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z" />
                              <path d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z" />
                              <path d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z" />
                              <path d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z" />
                            </g>
                          </svg>
                        </a>
                      </div>

                      <div>
                        <a
                          href="#"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          onClick={() => handleSignInWithTwitter()}
                        >
                          <span className="sr-only">Sign in with Twitter</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      </div>

                      <div>
                        <a
                          href="#"
                          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                          onClick={() => handleSignInWithFacebook()}
                        >
                          <span className="sr-only">Sign in with Facebook</span>
                          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                            <path
                              fillRule="evenodd"
                              d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>

                  {/* <div className='mt-6 relative'>
                    <div
                      className='absolute inset-0 flex items-center'
                      aria-hidden='true'
                    >
                      <div className='w-full border-t border-gray-300' />
                    </div>
                    <div className='relative flex justify-center text-sm'>
                      <span className='px-2 bg-white text-gray-500'>
                        Or continue with
                      </span>
                    </div>
                  </div> */}
                </div>

                {/* <div className='mt-6'>
                  <form action='#' method='POST' className='space-y-6'>
                    <div>
                      <label
                        htmlFor='email'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Email address
                      </label>
                      <div className='mt-1'>
                        <input
                          id='email'
                          name='email'
                          type='email'
                          autoComplete='email'
                          required
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        />
                      </div>
                    </div>

                    <div className='space-y-1'>
                      <label
                        htmlFor='password'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Password
                      </label>
                      <div className='mt-1'>
                        <input
                          id='password'
                          name='password'
                          type='password'
                          autoComplete='current-password'
                          required
                          className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                        />
                      </div>
                    </div>

                    <div className='flex items-center justify-between'>
                      <div className='flex items-center'>
                        <input
                          id='remember-me'
                          name='remember-me'
                          type='checkbox'
                          className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
                        />
                        <label
                          htmlFor='remember-me'
                          className='ml-2 block text-sm text-gray-900'
                        >
                          Remember me
                        </label>
                      </div>

                      <div className='text-sm'>
                        <a
                          href='#'
                          className='font-medium text-indigo-600 hover:text-indigo-500'
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                    <div>
                      <button
                        className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1462556791646-c201b8241a94?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1945&q=80"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  )
}

export default Login
