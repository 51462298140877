import React, { useEffect, useState } from 'react'
import { getAuth } from 'firebase/auth'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import firebaseApp from '../firebase/firebaseConfig'
import firebase from 'firebase/compat'
import { AuthContextDataType } from '../dataStructure'

export const AuthContext = React.createContext<AuthContextDataType>({ loading: true, user: null })

export const AuthProvider: React.FC = ({ children }: any) => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const auth = getAuth(firebaseApp)
    const unsubscribe = auth.onAuthStateChanged((u) => {
      // @ts-ignore
      setUser(u)
      setLoading(false)

      if (u !== null && !localStorage.getItem('sessionId')) {
        const sessionId = uuidv4()
        console.log(`set sessionId ${sessionId}`)
        localStorage.setItem('sessionId', sessionId)
      }
    })

    return () => unsubscribe()
  }, [])

  return <AuthContext.Provider value={{ user, loading }}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  children: PropTypes.object
}
