import { deleteDoc, doc } from 'firebase/firestore'
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { AuthContext } from '../../context/firebaseContext'
import { FirestoreContext } from '../../context/firestoreContext'
import { doSignOut } from '../../firebase/firebaseConfig'
import { ROUTE_PUBLIC_HOME } from '../../constants/routes'

const Logout = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const { firestore } = useContext(FirestoreContext)

  // Don't write useEffect(async () => ...) otherwise the component will crash.
  // If you need to await inside useEffect() do it in the following way.
  useEffect(() => {
    const signout = () => {
      const sessionId = localStorage.getItem('sessionId')
      localStorage.removeItem('sessionId')
      if (sessionId && user) {
        console.log(`Deleting users/${user.uid}/user_sessions/${sessionId} ...`)
        deleteDoc(doc(firestore, `users/${user.uid}/user_sessions/${sessionId}`))
          .then(() => {
            console.log(`Successfully delete session in firestore`)
          })
          .catch((reason) => {
            console.error(reason)
          })
          .finally(() => {
            doSignOut().then((success) => {
              console.log(`successfully logged out. Redirect to ${ROUTE_PUBLIC_HOME}`)
              navigate(ROUTE_PUBLIC_HOME)
            })
          })
      } else {
        navigate(ROUTE_PUBLIC_HOME)
      }
    }

    signout()
  }, [])
  return null
}

export default Logout
