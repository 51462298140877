import React, { useContext, useEffect, useState } from 'react'

import { AuthContext } from './firebaseContext'
import { getStorage } from 'firebase/storage'
import { getFirestore, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import PropTypes from 'prop-types'
import firebaseApp from '../firebase/firebaseConfig'
import { FirestoreContextDataType, UserAccessDataType, UserDocDataType, UserSessionDataType } from '../dataStructure'
export const FirestoreContext = React.createContext<FirestoreContextDataType>({
  firestore: undefined,
  rtdb: undefined,
  storage: undefined,
  userAccess: undefined,
  userDoc: undefined,
  userSessionDoc: undefined
})

export const FirestoreProvider = ({ children }: any) => {
  const authContext = useContext(AuthContext)
  const [firestore] = useState(getFirestore(firebaseApp))
  const [rtdb] = useState(getDatabase(firebaseApp))
  const [storage] = useState(getStorage(firebaseApp))
  const [userAccess, setUserAccess] = useState<UserAccessDataType>()
  const [userDoc, setUserDoc] = useState<UserDocDataType>()
  const [userSessionDoc, setUserSessionDoc] = useState<UserSessionDataType>()

  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId')
    console.log(`FirestoreProvider sessionId = ${sessionId}`)
    if (authContext.user && authContext.user.uid && sessionId) {
      getDoc(doc(firestore, 'users', authContext.user.uid)).then((document) => {
        if (document.exists()) {
          setUserDoc({ docId: document.id, docData: document.data() })

          getDoc(doc(firestore, `users/${authContext.user!.uid}/user_sessions/${sessionId}`)).then(async (userSessionDocument) => {
            if (!userSessionDocument.exists()) {
              setDoc(doc(firestore, `users/${authContext.user!.uid}/user_sessions/${sessionId}`), {
                createdAt: new Date(),
                heartbeatUpdatedAt: new Date()
              }).then((value) => {
                console.log()
              })
            }
          })
        }
      })

      getDoc(doc(firestore, 'user_access', authContext.user.uid)).then((document) => {
        if (document.exists()) {
          setUserAccess({ docId: document.id, docData: document.data() })
        }
      })

      const unsubscribe1 = onSnapshot(doc(firestore, 'users', authContext.user.uid), (snapshot) => {
        setUserDoc({ docId: snapshot.id, docData: snapshot.data() })
      })

      const unsubscribe2 = onSnapshot(doc(firestore, 'user_access', authContext.user.uid), (snapshot) => {
        setUserAccess({ docId: snapshot.id, docData: snapshot.data() })
      })

      const unsubscribe3 = onSnapshot(doc(firestore, `users/${authContext.user.uid}/user_sessions/${sessionId}`), (snapshot) => {
        setUserSessionDoc({ docId: snapshot.id, docData: snapshot.data() })
      })

      // Clean up. Stop listening for changes
      return () => {
        unsubscribe1()
        unsubscribe2()
        unsubscribe3()
      }
    }
  }, [authContext])

  return <FirestoreContext.Provider value={{ firestore, rtdb, storage, userDoc, userAccess, userSessionDoc }}>{children}</FirestoreContext.Provider>
}

FirestoreProvider.propTypes = {
  children: PropTypes.object
}
