// Set the environment before deployment
// export const ENVIRONMENT = 'PRODUCTION'
export const ENVIRONMENT = 'DEVELOPMENT'

export const VERSION = '0.1.0'

export let FIREBASE_HOSTING_DOMAIN = ''
export let GOOGLE_CLOUD_PROJECT_ID = ''
export let ORGANISATION_NAME = ''
export let FIREBASE_RTDB_URL = ''
export let GOOGLE_API_GATEWAY_2 = ''
export let EBAY_OAUTH_WEB_ENDPOINT = ''
// @ts-ignore
export let FIREBASE_CONFIG

// @ts-ignore
if (ENVIRONMENT === 'PRODUCTION') {
  FIREBASE_HOSTING_DOMAIN = 'octoplux.odeo.com.au'
  GOOGLE_CLOUD_PROJECT_ID = 'odeo-octoplux'
  ORGANISATION_NAME = 'ODEO Octoplux'
  FIREBASE_RTDB_URL = 'https://odeo-octoplux.asia-southeast1.firebasedatabase.app/'
  GOOGLE_API_GATEWAY_2 = 'https://odeo-api-gateway-2-1irh3sst.ts.gateway.dev'
  EBAY_OAUTH_WEB_ENDPOINT = 'https://auth.ebay.com/oauth2/authorize'
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyDIElqF2_-yjNLCZ2XiMicHSWFq5tbzP_Y',
    authDomain: 'octoplux.odeo.com.au',
    databaseURL: 'https://odeo-octoplux.asia-southeast1.firebasedatabase.app/',
    projectId: 'odeo-octoplux',
    storageBucket: 'odeo-octoplux.appspot.com',
    messagingSenderId: '119207563661',
    appId: '1:119207563661:web:8b84ad85d623152b852dc4',
    measurementId: 'G-6J8TC6BXRT'
  }
} else if (ENVIRONMENT === 'DEVELOPMENT') {
  FIREBASE_HOSTING_DOMAIN = 'octoplux-dev.odeo.com.au'
  GOOGLE_CLOUD_PROJECT_ID = 'octoplux-dev'
  ORGANISATION_NAME = 'ODEO Octoplux (Development)'
  FIREBASE_RTDB_URL = 'https://octoplux-dev-4207e.asia-southeast1.firebasedatabase.app/'
  GOOGLE_API_GATEWAY_2 = 'https://odeo-api-gateway-2-9j38y70f.ts.gateway.dev'
  EBAY_OAUTH_WEB_ENDPOINT = 'https://auth.sandbox.ebay.com/oauth2/authorize'
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBTPCyMR_1L6T2EXsEu6zD0Q-mMB97nstQ',
    authDomain: 'octoplux-dev.odeo.com.au',
    databaseURL: 'https://octoplux-dev-4207e.asia-southeast1.firebasedatabase.app/',
    projectId: 'octoplux-dev',
    storageBucket: 'octoplux-dev.appspot.com',
    messagingSenderId: '746832772095',
    appId: '1:746832772095:web:82ea7cd3ee2802d15f85ca',
    measurementId: 'G-2K1722S2MB'
  }
}

export const LOGGING_URL = `https://us-central1-${GOOGLE_CLOUD_PROJECT_ID}.cloudfunctions.net/api/log`

export const XERO_CLIENT_ID = '2DAC935070354F07968F64144A79EA3B'
export const XERO_REDIRECT_URI = `https://${FIREBASE_HOSTING_DOMAIN}/private/xero-oauth-success`
export const XERO_OAUTH_SCOPES = 'offline_access openid profile accounting.transactions accounting.settings accounting.contacts'

export const EBAY_OAUTH_CLIENT_ID = 'SystemsK-KarosoMe-PRD-251ca6568-07bacd9b'
export const EBAY_OAUTH_DEV_ID = '014541e4-4bbf-4561-8141-0b18f8986648'
export const EBAY_BASE_URL = 'api.ebay.com'
export const EBAY_OAUTH_REDIRECT_URL = 'Systems_Karoso-SystemsK-Karoso-xcpcwa'
export const EBAY_OAUTH_SCOPES = [
  'https://api.ebay.com/oauth/api_scope',
  'https://api.ebay.com/oauth/api_scope/sell.marketing.readonly',
  'https://api.ebay.com/oauth/api_scope/sell.marketing',
  'https://api.ebay.com/oauth/api_scope/sell.inventory.readonly',
  'https://api.ebay.com/oauth/api_scope/sell.inventory',
  'https://api.ebay.com/oauth/api_scope/sell.account.readonly',
  'https://api.ebay.com/oauth/api_scope/sell.account',
  'https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly',
  'https://api.ebay.com/oauth/api_scope/sell.fulfillment',
  'https://api.ebay.com/oauth/api_scope/sell.analytics.readonly',
  'https://api.ebay.com/oauth/api_scope/sell.finances',
  'https://api.ebay.com/oauth/api_scope/sell.payment.dispute',
  'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly'
]

export const USER_SESSION_MAX_IDLE_TIME_MINUTES = 30
