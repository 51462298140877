import React from 'react'
import './App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import { TestSlideOver1 } from './pages/test/TestSlideOver1'
import { PublicHome } from './pages/PublicHome'
import { ProtectedNavbar } from './components/ProtectedNavbar'
import { ROUTE_LOGIN, ROUTE_LOGOUT, ROUTE_PUBLIC_HOME } from './constants/routes'
import Login from './pages/Login'
import Logout from './pages/Logout'

function App() {
  const location = useLocation()
  return (
    <div className="App">
      {location.pathname !== ROUTE_LOGIN ? (
        <header className="sticky top-0 z-50">
          <ProtectedNavbar />
        </header>
      ) : null}

      <Routes>
        <Route path={ROUTE_PUBLIC_HOME} element={<PublicHome />} />
        <Route path="/test1" element={<TestSlideOver1 />} />
        <Route path={ROUTE_LOGIN} element={<Login />} />
        <Route path={ROUTE_LOGOUT} element={<Logout />} />
      </Routes>
      {location.pathname !== ROUTE_LOGIN ? (
        <footer className="bg-slate-800 mt-auto p-5">
          <h1 className="text-2xl md:text-4xl text-white">Footer</h1>
        </footer>
      ) : null}
    </div>
  )
}

export default App
