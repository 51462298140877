export const ROUTE_PUBLIC_HOME = '/'
export const ROUTE_LOGIN = '/login'

// protected routes (must to logged in to access these routes)
export const ROUTE_PROTECTED_HOME = '/app'
export const ROUTE_LOGOUT = '/app/logout'
export const ROUTE_CLIENTS = '/app/clients'
export const ROUTE_ORDERS = '/app/orders'
export const ROUTE_USER_PROFILE = '/app/user/profile'
export const ROUTE_USER_ONBOARDING = '/app/onboarding'
